<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>Admin Live Balance</h5>
                <DataTable v-model:expandedRows="expandedRows" :value="clientLiveBalanceList" :lazy="true" :paginator="true"
                    :rows="30" :totalRecords="totalRecords" :loading="loading" @page="onPage" class="p-datatable-users"
                    data-key="maa1" :rowHover="true" tableStyle="min-width: 60rem"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Total Amount : <span class="indigo-color"> {{
                                formatCurrency(this.totalLiveBalance ? this.totalLiveBalance : '0') }}</span>
                            </h5>
                        </template>
                        <template v-slot:right>
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                                @click="filterlisting()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="id" header="Sr." headerStyle="width: 10%">
                        <template #body="{ index }">
                            {{ index+=1 }}
                        </template>
                    </Column>

                    <Column expander style="width: 5rem" />
                    <Column header="Admin Name" headerStyle="width: 65%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.clients_legal_name }}</div>
                        </template>
                    </Column>
                    <!-- <Column header="Debit Amount" headerStyle="width: 20%" class="p-text-right">
                        <template #body="{ data }">
                            <div class="muted-text">{{ formatCurrency(data.total_debit_amt) }}</div>
                        </template>
                    </Column> -->
                    <Column header="Live Balance" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div class="muted-text">
                                <span v-if="data.total_balance > 0" style="color: green;"> {{
                                    formatCurrency(data.total_balance) }}
                                </span>
                                <span v-if="data.total_balance == 0"> {{
                                    formatCurrency(data.total_balance) }}
                                </span>
                                <span v-if="data.total_balance < 0" style="color: rgb(211, 6, 78);"> {{
                                    formatCurrency(data.total_balance) }}
                                </span>
                            </div>
                        </template>
                    </Column>

                    <template #expansion="{ data }">
                        <div class="p-3">
                            <DataTable :value="data.clienBillingInfo">
                                <Column header="Billing Company" sortable>
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">{{ data.marketing_name }}
                                            <span v-if="data.current_billing_active == 1" style="color: green;"> (Current
                                                Billing Company)
                                            </span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Balance Amount" sortable>
                                    <template #body="{ data }">
                                        <span v-if="data.balance_amount > 0" style="color: green;"> {{
                                            formatCurrency(data.balance_amount) }}
                                        </span>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>

    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="state">Admin</label>
                    <MultiSelect v-model="clientFilter" :options="clientList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus @click="getFilteredData(clientFilter)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
</template>
<script>
import ApiService from '../service/ApiService';
export default {
    data() {
        return {
            clientLiveBalanceList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            totalLiveBalance: 0,
            clientList: [],
            clientFilter: '',
            showFillFilter: '',
            filterListingDialog: false,
            expandedRows: [],
            itemsPerPage: 30
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getLiveBalance({ page_no: this.page_no, itemsPerPage: this.itemsPerPage });
    },
    methods: {
        getLiveBalance(e) {
            this.loading = true;
            this.ApiService.getLiveBalance(e).then((data) => {
                if (data.status == 200) {
                    this.clientLiveBalanceList = data.data;
                    this.totalRecords = data.count;
                    this.totalLiveBalance = data.totalLiveBalance;
                    this.loading = false;
                } else {
                    this.clientLiveBalanceList = null;
                    this.totalRecords = 0;
                    this.loading = false;
                }
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getLiveBalance({ page_no: this.page_no, itemsPerPage: this.itemsPerPage });
        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },

        filterlisting() {
            this.filterListingDialog = true;
            this.getAdminListOnly();
        },
        getAdminListOnly() {
            this.ApiService.getAdminListOnly().then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data.records;
                } else {
                    this.clientList = null;
                }
            });
        },
        getFilteredData(clientFilter) {
            this.loading = true;
            this.clientFilter = clientFilter;
            this.getLiveBalance({
                clientFilter: this.clientFilter
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.clientFilter = "";
            this.getLiveBalance();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
